type Dialect = 'db2' | 'mariadb' | 'mysql' | 'n1ql' | 'plsql' | 'postgresql' | 'redshift' | 'spark' | 'sql' | 'tsql'

let activeDialect = null as Dialect

const localStoageDefaultDialectKey = 'default-sql-dialect'

export const getActiveDialect = () => activeDialect!

const dialectOptions = document.querySelectorAll('[data-dialect]') as NodeListOf<HTMLElement>
const dialectButton = document.getElementById("dialect-button")
const dialectLabel = document.getElementById("dialect-label")

const menu = document.getElementById('dialect-menu')

const toggleMenu = () => {
    if(menu.classList.contains("hidden")){
        menu.classList.remove("hidden")
    } else {
        menu.classList.add("hidden")
    }
}


export const setup = (formatSql: () => void) => {
    const setActiveDialect = (dialect: Dialect) => {
        console.log(dialect)
        const element = document.querySelector(`[data-dialect="${dialect}"]`) as HTMLElement
        element.classList.add('bg-gray-100', 'text-gray-900')
        element.classList.remove("text-gray-700")
    
        dialectLabel.innerText = element.innerText
        localStorage.setItem(localStoageDefaultDialectKey, dialect)
        activeDialect = dialect
        formatSql()
    }
    
    dialectOptions.forEach(element => {
        element.addEventListener('click', () => {
            dialectOptions.forEach(option => {
                option.classList.remove('bg-gray-100', 'text-gray-900')
                option.classList.add("text-gray-700")
            })
            setActiveDialect(element.getAttribute('data-dialect') as Dialect)
            toggleMenu()
        })
    })
    
    dialectButton.addEventListener("click", toggleMenu)
    setActiveDialect((localStorage.getItem(localStoageDefaultDialectKey) ?? 'mysql') as Dialect)
}
