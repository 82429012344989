import { format } from 'sql-formatter';
import copy from 'copy-to-clipboard'
import Prism from 'prismjs';
import 'prismjs/components/prism-sql';
import {getActiveDialect, setup as setupDialectSelection} from './dialect-selection'

const textArea = document.getElementById("input") as HTMLFormElement;
const output = document.getElementById("output")
const formatSql = () => {
    const sql = format(textArea.value, {
        uppercase: true,
        language: getActiveDialect(),
    })
    output.innerHTML = sql
    Prism.highlightElement(output)
}
textArea.addEventListener('keyup', formatSql);
textArea.addEventListener('keyup', () => {
    sessionStorage.setItem('format-sql-query', textArea.value)
});

const copyButton = document.getElementById('copybutton')
copyButton.addEventListener('click', () => {
    if(!copyButton.hasAttribute('data-default-text')){
        copyButton.setAttribute('data-default-text', copyButton.innerText)
    }
    copy(output.innerText, {message: 'Formatted SQL'})
    copyButton.innerText = "Copied!"
    setTimeout(() => {
        copyButton.innerText = copyButton.getAttribute('data-default-text')
    }, 1500)
})

setupDialectSelection(formatSql)
textArea.value = sessionStorage.getItem('format-sql-query')
if(textArea.value){
    formatSql()
}